import React from 'react'
import Menu from './Menu'
import { useNavigate } from 'react-router'
import '../Css/Paginas.css'

function OrigemTranferencias() {
  const navegacao = useNavigate();

  function handleClickBanco() {
    navegacao('/transbanco')
  }

  function handleClickCaixa() {
    navegacao('/transcaixa')
  }
  return (
    <div className='row'>
      <Menu />
      <div className='col-9'>
        <h1>Origem</h1>
        <hr />
        <div className='row'>
          <div className='col-2 m-2 text-center hover' onClick={handleClickBanco}>
            <i className="bi bi-bank" style={{ fontSize: '3rem' }}></i>
            <p>Banco</p>
          </div>
          <div className='col-2 m-2  text-center hover' onClick={handleClickCaixa}>
            <i className="bi bi-cash-coin" style={{ fontSize: '3rem' }}></i>
            <p>Caixa</p>

          </div>
        </div>
      </div>
    </div>
  )
}

export default OrigemTranferencias