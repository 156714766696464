import React from 'react'
import { useFetch } from '../Hooks/useFetch.js'
import { GlobalContext } from '../Componentes/GlobalContext'
import Menu from './Menu.js';
import { useNavigate } from 'react-router';
function Pendencias() {
    const { servidor, profile } = React.useContext(GlobalContext);
    const [movimento, setMovimento] = React.useState('/prestacoes/')
    const {request,data, error, loading, response } = useFetch();
    const navegacao = useNavigate()

function filtraPendencias(data) {
    return data.transferencia === 'st' && data.categoria === null
}
localStorage.setItem('movimento', movimento)
React.useEffect(() => {
    request(servidor + movimento + profile.casa)
},[movimento])

function editar(event) {
    event.preventDefault()
    const idMovimento = event.target.getAttribute('id')

    if(movimento === '/prestacoes/') {
      localStorage.setItem('movimento','/prestacao/')
      navegacao('/editaMovimento/?' + idMovimento)
    }
    if(movimento === '/cob/') {
      localStorage.setItem('movimento','/caixa/')
      navegacao('/editaMovimento/?' + idMovimento)
    }

}
function buscaMovimento({target}) {
  const mov = target.value
  setMovimento(mov)
}
if(error) return <p>{error}</p>
if(loading) return <p>Carregando...</p>
if(data)
  return (
    <div className='row'>
    <Menu />
    <div className='col-9'>
      <div className='col-3'>
        <br />
        <label>Movimento</label>
        <select className='form-control' onChange={buscaMovimento}>
          <option>Selecione:</option>
          <option value='/cob/'>CAIXA</option>
          <option value='/prestacoes/'>BANCO</option>
        </select>
        <br /> 
        <h3>{movimento === '/prestacoes/' ? 'BANCO': 'CAIXA'}</h3>
      </div>
          <table className='table table-striped ' style={{ marginTop: '40px' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Tipo</th>
                <th>Data</th>
                <th>Categoria</th>
                <th>Fornecedor</th>
                <th>Descrição</th>
                <th>Valor</th>
                <th>Acões</th>
              </tr>
            </thead>
            <tbody>
              {data.filter(filtraPendencias).map((item) => {
                return (
                  <tr key={item.id} >
                    <td id={item.id} >{item.id}</td>
                    <td className='tipo'>{item.tipo}</td>
                    <td>{item.data.split('-').reverse().join('/')}</td>
                    <td>{item.categoria}</td>
                    <td>{item.fornecedor}</td>
                    <td>{item.descricao}</td>
                    <td className='valor'>{item.valor}</td>
                    <td>
                      <button className="btn btn-outline-primary btn-sm" id={item.id} onClick={editar}>Editar</button>
                    </td>
                  </tr>
                )
              })}
            </tbody>

          </table>

        </div>
    </div>
  )
  else  return null
}
export default Pendencias