import React from 'react'

function Botao({ id, nome, ...props }) {
  return (
    <div>
      <button id={id} {...props} >{nome}</button>
    </div>
  )
}

export default Botao