import React from 'react'
import axios from 'axios'
import { GlobalContext } from './GlobalContext'

function CampoSelectCategorias({ value, setValue, label,tipo, ...props }) {
  const { profile, servidor, fraternidade } = React.useContext(GlobalContext);
  const [categorias, setCategorias] = React.useState(null)

  async function pegaCategorias() {
    axios.get(`${servidor + "/categorias/"}`, {
      headers: {
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => {
      setCategorias(res.data)
    })
      .catch((error) => console.log(error))
  }

  React.useEffect(() => {
    pegaCategorias()
  }, [])

  if (categorias === null) return null
  return (
    <>
      <label>{label}</label>
      <select value={value} onChange={({ target }) => setValue(target.value)} {...props}>
        <option value="1" disabled>SELECIONE A CATEGORIA...</option>
        {categorias && categorias.filter(categoria => categoria.tipo === tipo).map((categoria) => {
          return (
            <>
              <option key={categoria.id} value={categoria.id}>{categoria.categoria}</option>
            </>
          )
        })}
      </select>
    </>
  )
}

export default CampoSelectCategorias