import React from 'react'
import axios from 'axios'
import { GlobalContext } from '../Componentes/GlobalContext'
import Botao from '../Componentes/Botao'
import Menu from '../Pages/Menu'
import CampoInput from '../Componentes/CampoInput'
import CriarPdf from '../Componentes/Relatorio'

function RelatorioCasa() {
  const { profile, servidor } = React.useContext(GlobalContext)
  const [banco, setBanco] = React.useState(null)
  const [caixa, setCaixa] = React.useState(null)
  const [periodo, setPeriodo] = React.useState('')

  async function relatorio(event) {
    event.preventDefault()
    cob(profile.casa)
    prestacoes(profile.casa)
  }
  async function cob(id) {
    await axios.get(`${servidor + "/cob/" + id}`, {
      headers: {
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => setCaixa(res.data))
      .catch((error) => console.log(error))
  }
  async function prestacoes(id) {
    await axios.get(`${servidor + "/prestacoes/" + id}`, {
      headers: {
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => setBanco(res.data))
      .catch((error) => console.log(error))
  }

  return (
    <div className='row'>
      <Menu />
      <div className='col-9'>
        <h1>Relátorio</h1>
        <hr />
        <form  className='row' id="formulario" onSubmit={relatorio}>

          <div className='col-4'>
            <CampoInput type="month" className="form-control limpaInput" value={periodo} setValue={setPeriodo} label="Periodo" required />
          </div>
          <div className='col-4'>
            <br />
            <Botao nome="Gerar" className="btn btn-primary text-end" onClick={CriarPdf(banco,caixa,periodo,profile.casa)} />
          </div>

        </form>
      </div >
    </div >
  )
}

export default RelatorioCasa