import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Prestacoes from "./Pages/Prestacoes";
import Caixas from "./Pages/Caixas";
import Header from "./Componentes/Header";
import NaoEncontrada from "./Pages/NaoEncontrada";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Footer from "./Componentes/Footer";
import { GlobalProvider } from './Componentes/GlobalContext'
import Administracao from "./Pages/Administracao";
import AddUsuario from "./Pages/AddUsuario";
import AddCasa from "./Pages/AddCasa";
import EditaDados from "./Pages/EditaDados";
import RelatorioAdm from "./Pages/RelatorioAdm";
import OrigemTranferencias from "./Pages/OrigemTranferencias";
import TransBanco from "./Pages/TransBanco";
import TransCaixa from "./Pages/TransCaixa";
import RelatorioCasa from "./Pages/RelatorioCasa";
import Categorias from "./Pages/Categorias";
import Pendencias from "./Pages/Pendencias";
import EditaMovimento from "./Pages/EditaMovimento";
import Movimentos from "./Pages/Movimentos";
import PendenciasAdm from "./Pages/PendenciasAdm";
import EditaMovimentosAdm from "./Pages/EditaMovimentosAdm";

function App() {

  return (
    <GlobalProvider>
      <BrowserRouter>
        <div>
          <Header />
          <div className="container">
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/perfil" element={<Dashboard />} />
              <Route path="/movimento" element={<Prestacoes />} />
              <Route path="/movimentocaixa" element={<Caixas />} />
              <Route path="/transbanco" element={<TransBanco />} />
              <Route path="/transcaixa" element={<TransCaixa />} />
              <Route path="/relatorioCasa" element={<RelatorioCasa />} />
              <Route path="/origemtransferencias" element={<OrigemTranferencias />} />
              <Route path="/categorias" element={<Categorias />} />
              <Route path="/administracao" element={<Administracao />} />
              <Route path="/addusuario" element={<AddUsuario />} />
              <Route path="/addcasa" element={<AddCasa />} />
              <Route path="/pendencias" element={<Pendencias />} />
              <Route path="/pendenciasAdm" element={<PendenciasAdm />} />
              <Route path="/meusdados" element={<EditaDados />} />
              <Route path="/relatorioadm" element={<RelatorioAdm />} />
              <Route path="/editaMovimento" element={<EditaMovimento />} />
              <Route path="/editaMovimentosAdm" element={<EditaMovimentosAdm />} />
              <Route path="/movimentos" element={<Movimentos />} />
              <Route path="*" element={<NaoEncontrada />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
