import React from 'react'
import Menu from './Menu'
import { useNavigate } from 'react-router'
import '../Css/Paginas.css'

const Administracao = () => {
  const navegacao = useNavigate();

  function handleClickRelatorio() {
    navegacao('/relatorioadm')
  }
  function handleClickMovimentos() {
    navegacao('/movimentos')

  }
  function handleClickCasas() {
    navegacao('/addcasa')

  }
  function handleClickUsuarios() {
    navegacao('/addusuario')
  }
  function handleClickCategorias() {
    navegacao('/categorias')
  }
  return (

    <div className='row'>
      <Menu />
      <div className='col-9'>
        <h1>Administração</h1>
        <hr />
        <div className='row'>
        <div className='col-2 m-2  text-center hover' onClick={handleClickRelatorio}>
            <i className="bi bi-clipboard-check" style={{ fontSize: '3rem' }}></i>
            <p>Relatório</p>
          </div>
          <div className='col-2 m-2  text-center hover' onClick={handleClickMovimentos}>
            <i className="bi bi-arrow-down-up" style={{ fontSize: '3rem' }}></i>
            <p>Movimentos</p>
          </div>
          <div className='col-2 m-2 text-center hover' onClick={handleClickCasas}>
            <i className="bi bi-house-add " style={{ fontSize: '3rem' }}></i>
            <p>Nova casa</p>
          </div>
          <div className='col-2 m-2  text-center hover' onClick={handleClickUsuarios}>
            <i className="bi bi-person-add" style={{ fontSize: '3rem' }}></i>
            <p>Novo usuário</p>
          </div>
          <div className='col-2 m-2  text-center hover' onClick={handleClickCategorias}>
            <i className="bi bi-bookmark-plus" style={{ fontSize: '3rem' }}></i>
            <p>Categorias</p>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Administracao
