import React from 'react'
import Menu from './Menu'
import { GlobalContext } from '../Componentes/GlobalContext'
import { useNavigate } from 'react-router'

function Dashboard() {
  const navegacao = useNavigate();
  const [valor, setValor] = React.useState('');
  function onClickDados() {
    navegacao('/meusdados')
  }
  const { profile } = React.useContext(GlobalContext);
  function logado() {
    if (profile.nome === undefined) {
      window.location.reload()
    }
  }
  React.useEffect(() => {
    logado()
  }, [])
  return (
    <div className='row'>
      <Menu />
      <div className='col-9' >
        <h1>Perfil - {profile.nome}</h1>
        <hr />
        <div className='row'>
          <div className='col-2 m-2 text-center hover' onClick={onClickDados}>
            <i className="bi bi-fingerprint" style={{ fontSize: '3rem' }}></i>
            <p>Meus dados</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
