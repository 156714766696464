import React from 'react'

function CampoAnexo({ label, id, name, ...props }) {
  return (
    <>
      <label>{label}</label>
      <input type="file" id={id} name={name} {...props} />
    </>
  )
}

export default CampoAnexo