import React from 'react'
import { useNavigate } from 'react-router'
import Botao from '../Componentes/Botao';
import CampoInput from '../Componentes/CampoInput';
import axios from 'axios';
import { GlobalContext } from '../Componentes/GlobalContext';
function Login() {
  const navegacao = useNavigate();
  const [usuario, setUsuario] = React.useState('');
  const [password, setPassword] = React.useState('')
  const [erro, setErro] = React.useState('')
  const { servidor } = React.useContext(GlobalContext)

  async function handleSubmit(event) {
    event.preventDefault()
    await axios.post(`${servidor}` + "/auth", {
      "email": usuario,
      "sub": password
    })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem('profile', JSON.stringify(res.data))
          navegacao('/perfil')
        }
      })
      .catch((error) => {
        console.log(error.toJSON())
        setErro(error.response.data)
      })


  }

  return (
    <div>
      <form className='row mt-5' onSubmit={handleSubmit} style={{ width: '50%', margin: '0 auto', marginBottom: '25vh' }}>
        <h1 className='mb-5'>Login</h1>
        <div className="col-12">
          <CampoInput type="email" label="Usuário:" placeholder="Digite seu email" value={usuario} setValue={setUsuario} className='form-control' required />
        </div>
        <div className="col-12">
          <CampoInput type="password" label="senha:" placeholder="Digite sua senha" value={password} setValue={setPassword} className='form-control' required />
        </div>
        <br />
        <p>{erro}</p>
        <Botao className="btn btn-primary col-4 align-right" name="botaoLogin" id="botaoLogin" nome="Entrar" />
        {/* <a href="/" value="esquecia a senha">Esqueci a senha</a> */}
      </form>
    </div>
  )
}

export default Login