import React from 'react'
import axios from 'axios'
import { GlobalContext } from '../Componentes/GlobalContext'

function CampoSelectCasas({ value, setValue, label, ...props }) {
  const { profile, servidor, fraternidade } = React.useContext(GlobalContext);
  const [casa, setCasa] = React.useState(null)

  async function pegaCasas() {
    axios.get(`${servidor + "/casas/"}`, {
      headers: {
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => {
      setCasa(res.data)
    })
      .catch((error) => console.log(error))
  }

  React.useEffect(() => {
    pegaCasas()
  }, [])

  if (casa === null) return null
  return (
    <>
      <label>{label}</label>
      <select value={value} onChange={({ target }) => setValue(target.value)} {...props}>
        <option value="" disabled selected>SELECIONE...</option>
        {casa && casa.filter((item) => item.id !== 1 && item.id !== fraternidade[profile.casa].id).map((casa) => {
          return (
            <>
              <option key={casa.id} value={casa.id}>{casa.nome}</option>
            </>
          )
        })}
      </select>
    </>
  )
}

export default CampoSelectCasas