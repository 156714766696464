import React from 'react'
import '../Css/Modal.css'
import axios from 'axios'
import CampoSelect from '../Componentes/CampoSelect'
import CampoInput from './CampoInput'
import { GlobalContext } from '../Componentes/GlobalContext'
import Botao from './Botao'
import Modal from './Modal'
import CampoSelectCategorias from '../Componentes/CampoSelectCategorias.js'

const ModalEditaPrestacao = ({ pegaId }) => {
  const { profile, servidor } = React.useContext(GlobalContext)
  const [tipo, setTipo] = React.useState('');
  const [fornecedor, setFornecedor] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [data, setData] = React.useState('');
  const [categorias, setCategorias] = React.useState('');
  const [valor, setValor] = React.useState('');
  const [anexo, setAnexo] = React.useState('');
  const [msg, setMsg] = React.useState('')
  const [mostrarModal, setMostrarModal] = React.useState(false)


  async function editaPrestacao() {
    await axios.get(`${servidor + '/prestacao/' + pegaId}/`, {
      headers: {
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => {
      setTipo(res.data.tipo)
      setFornecedor(res.data.fornecedor)
      setDescricao(res.data.descricao)
      setData(res.data.data)
      setCategorias(res.data.categoria)
      setValor(res.data.valor)
      setAnexo(res.data.anexo)
    })

  }
  function handleSubmitEvento(event) {
    event.preventDefault()
    window.location.reload(false);
  }
  async function handleSubmitEditor(event) {
    event.preventDefault()
    const atualizaDados = new FormData();
    atualizaDados.append("tipo", tipo)
    atualizaDados.append("fornecedor", fornecedor)
    atualizaDados.append("descricao", descricao)
    atualizaDados.append("data", data)
    atualizaDados.append("categoria", categorias)
    atualizaDados.append("valor", valor)
    atualizaDados.append("anexo", anexo)
    atualizaDados.append("id_usuarios", profile.userId)
    atualizaDados.append("id_casas", profile.casa)
    console.log([...atualizaDados])
    await axios.put(`${servidor + "/prestacoes/" + pegaId}`, atualizaDados,
      { headers: { 'Authorization': `token ${profile.token}` } }).then((res) => {
        if (res.status === 200) {
          setMsg("Atualização feita com sucesso!")
          setMostrarModal(true)
        }
        console.log(res)
      })
  }

  React.useEffect(() => {
    editaPrestacao()
  }, [])

  return (
    <div className='modalContainer'>
      {mostrarModal && <Modal msg={msg} onClose={() => { setMostrarModal(false); }} />}
      <div className='modalMsg'>
        <form className='row' style={{ padding: '10px' }}>
          <div className='col-3'>
            <CampoSelect options={['ENTRADA', 'SAIDA']} label="Tipo" id="tipo" value={tipo} setValue={setTipo} name="tipo" className='form-control' required />
          </div>
          <div className='col-4'>
            <CampoSelectCategorias label="Categoria" value={categorias} setValue={setCategorias} className='form-control' tipo={tipo} required />
          </div>
          <div className='col-3'>
            <CampoInput type="date" id="data" name="data" label="Data" value={data} setValue={setData} className='form-control' required />
          </div>
          <div className='col-2'>
            <CampoInput type="text" id="valor" name="valor" label="Valor" value={valor} setValue={setValor} className='form-control number' required />
          </div>
          <div className='col-6'>
            <CampoInput type="text" id="fornecedor" name="fornecedor" label="Fornecedor" value={fornecedor} setValue={setFornecedor} required className='form-control' />
          </div>
          <div className='col-6'>
            <CampoInput type="text" id="descricao" name="descricao" label="Descrição" value={descricao} setValue={setDescricao} required className='form-control' />
          </div>
          <div className='col-12'>
            <CampoInput type="text" id="anexo-view" name="descricao" label="Anexo" value={anexo} setValue={setAnexo} required className='form-control' disabled />
          </div>
          <div className='col-2'>
            <br />
            <Botao nome="Atualizar" id="adicionar" className='btn btn-primary' onClick={handleSubmitEditor} />
          </div>
          <div className='col-2'>
            <br />
            <Botao nome="Fechar" id="cancelar" className='btn btn-danger' onClick={handleSubmitEvento} />
          </div>
        </form>
      </div>
    </div>

  )
}

export default ModalEditaPrestacao
