import React from 'react'
import CampoInput from '../Componentes/CampoInput'
import CampoSelect from '../Componentes/CampoSelect';
import CampoAnexo from '../Componentes/CampoAnexo';
import Botao from '../Componentes/Botao'
import axios from 'axios';
import Menu from './Menu';
import { GlobalContext } from '../Componentes/GlobalContext'
import ModalEditaPrestacao from '../Componentes/ModalEditaPrestacao';
import Modal from '../Componentes/Modal';
import Saldo from '../Componentes/Saldo';
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min.js';
import CampoSelectCategorias from '../Componentes/CampoSelectCategorias.js'
function Prestacoes() {
  const [tipo, setTipo] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [fornecedor, setFornecedor] = React.useState('');
  const [categorias, setCategorias] = React.useState('');
  const [data, setData] = React.useState('');
  const { profile, servidor } = React.useContext(GlobalContext);
  const [mostrarModalEditor, setMostrarModalEditor] = React.useState(false)
  const [pegaId, setPegaId] = React.useState('')
  const [pegaTransferencia, setPegaTransferencia] = React.useState('')
  const [mostrarModal, setMostrarModal] = React.useState(false)
  const [msg, setMsg] = React.useState('')
  const [carregando, setCarregando] = React.useState(false)

  //paginacao


  //paginacao




  function handleSubmit(event) {
    event.preventDefault()
    setCarregando(true)
    const valor = document.getElementById('valor').value
    const formData = new FormData();
    const anexo = document.querySelector('#anexo').files[0]
    const novoNome = Date.now() + "--" + anexo.name;
    formData.append("tipo", tipo)
    formData.append("descricao", descricao)
    formData.append("fornecedor", fornecedor)
    formData.append("data", data)
    formData.append("categoria", categorias)
    formData.append("valor", valor)
    formData.append("anexo", anexo, novoNome)
    formData.append("id_usuarios", profile.userId)
    formData.append("id_casas", profile.casa)
    formData.append("transferencia", 'st')

    axios.post(`${servidor}` + "/prestacoes", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `token ${profile.token}`
      }
    })
      .then((res) => {
        setCarregando(false)
        if (res.status === 201) {
          setMsg("Movimento salvo com sucesso!")
          setMostrarModal(true)
        }
      })
      .catch((error) => console.log(error))
  }

  async function excluir(event) {
    event.preventDefault()
    setCarregando(true)
    const idPrestacoes = event.target.parentNode.parentNode.children[0].getAttribute('id')
    console.log(idPrestacoes)
    await axios.get(`${servidor + "/prestacao/" + idPrestacoes}/`, {
      headers: {
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => {
      if (res.data.transferencia === 'st' && res.data.id_usuarios === profile.userId) {
        axios.delete(`${servidor + "/prestacoes/" + res.data.id}`,
          { headers: { 'Authorization': `token ${profile.token}` } })
          .then((res) => {
            if (res.status === 200) {
              window.scrollTo(0, 0)
              setCarregando(false)
              setMsg("Movimento excluido com sucesso!!!")
              setMostrarModal(true)
            }
          })
      } else {
        window.scrollTo(0, 0)
        setCarregando(false)
        setMsg("Um movimento que possui transfêrencia só pode ser excluido na guia de transferências.")
        setMostrarModal(true)
        return null
      }
    })

  }

  async function downloadAnexo(event) {
    event.preventDefault()
    setCarregando(true)
    const idPrestacoes = event.target.parentNode.parentNode.children[0].getAttribute('id')
    const nomeAnexo = event.target.parentNode.getAttribute('name')
    await fetch(`${servidor + '/download/' + idPrestacoes}/${nomeAnexo}`, {
      headers: {
        'Authorization': `token ${profile.token}`
      }
    })
      .then(res => res.blob())
      .then(data => {
        let a = document.createElement("a")
        a.href = window.URL.createObjectURL(data)
        a.download = nomeAnexo;
        setCarregando(false)
        a.click();
      })
  }

  async function editar(event) {
    event.preventDefault()
    const idPrestacao = event.target.parentNode.parentNode.children[0].getAttribute('id')
    await axios.get(`${servidor + '/prestacao/' + idPrestacao}`, {
      headers: {
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => {
      if (res.data.transferencia !== 'st') {
        window.scrollTo(0, 0)
        setMsg('Uma transferência não pode ser editada')
        setMostrarModal(true)
      } else {
        window.scrollTo(0, 0)
        setPegaId(idPrestacao)
        setMostrarModalEditor(true)
      }
    })
    return null
  }
  const [dados, setDados] = React.useState(null)
  async function pegaDados() {
    await axios.get(`${servidor + "/prestacoes/" + profile.casa}`, {
      headers: {
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => { setDados(res.data) })
      .catch((error) => {
        console.log(error)
        window.location.reload()
      }
      
      )

  }

  $('#valor').mask('000.000.000.000.000,00', { reverse: true });

  React.useEffect(() => {
    pegaDados()
  }, [mostrarModal])
  return (
    <div className='row'>
      <Menu />
      <form className='col-9' id="formPrestacoes" onSubmit={handleSubmit}>
        {mostrarModalEditor && <ModalEditaPrestacao pegaId={pegaId} pegaTransferencia={pegaTransferencia} />}
        {mostrarModal && <Modal msg={msg} onClose={() => setMostrarModal(false)} />}
        <div className='row'><h1 className='col-9'>Movimento Banco</h1><Saldo /></div>
        <hr />
        <div className='row'>
          <div className='col-2'>
            <CampoSelect options={['ENTRADA', 'SAIDA']} label="Tipo" id="tipo" value={tipo} setValue={setTipo} name="tipo" className='form-control' required />
          </div>
          <div className='col-4'>
            <CampoSelectCategorias value={categorias} setValue={setCategorias} tipo={tipo} label='Categorias' className='form-control' />
          </div>
          <div className='col-3'>
            <CampoInput type="date" id="data" name="data" label="Data" value={data} setValue={setData} className='form-control' required />
          </div>
          <div className='col-3'>
            <label>Valor</label>
            <input id="valor" className='form-control' placeholder="0,00" required />
          </div>
          <div className='col-6'>
            <CampoInput type="text" id="fornecedor" name="fornecedor" label="Fornecedor" value={fornecedor} setValue={setFornecedor} required className='form-control' />
          </div>
          <div className='col-6'>
            <CampoInput type="text" id="descricao" name="descricao" label="Descrição" value={descricao} setValue={setDescricao} required className='form-control' />
          </div>
          <div className='col-10'>
            <CampoAnexo id="anexo" name="anexo" label="Anexo" className='form-control anexo' accept=".pdf, .jpeg, .jpg, .png" required />
          </div>
          <div className='col-2'>
            <br />
            <Botao nome="Adicionar" id="adicionar" className='btn btn-primary' />
          </div>
        </div>
        {carregando && <p className='text-success text-center border border-success mt-5 fw-bold'>Carregando...</p>}
        <div className='col-12'>
          <table className='table table-striped' style={{ marginTop: '40px' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Tipo</th>
                <th>Data</th>
                <th>Fornecedor</th>
                <th>Categoria</th>
                <th>Descrição</th>
                <th>Valor</th>
                <th>Anexo</th>
                <th>Acões</th>
              </tr>
            </thead>
            <tbody>
              {dados && dados.map((item) => {
                return (

                  <tr key={item.id}>
                    <td id={item.id}>{item.id}</td>
                    <td className='tipo'>{item.tipo}</td>
                    <td>{item.data.split('-').reverse().join('/')}</td>
                    <td>{item.fornecedor}</td>
                    <td>{item.categoria}</td>
                    <td>{item.descricao}</td>
                    <td className='valor'>{item.valor}</td>
                    <td name={item.anexo}><button className="btn btn-outline-success btn-sm" onClick={downloadAnexo}>Download</button></td>
                    <td style={{ width: '15%' }}>
                      <button className="btn btn-outline-primary btn-sm" id="btn-editar" onClick={editar}>Editar</button> {' '}
                      <button className="btn btn-outline-danger btn-sm" onClick={excluir} >Excluir</button>
                    </td>
                  </tr>
                )
              })}
            </tbody>

          </table>

        </div>
      </form>
    </div>
  )
}

export default Prestacoes