import React from 'react'
import CampoInput from '../Componentes/CampoInput'
import Botao from '../Componentes/Botao'
import Menu from './Menu'
import axios from 'axios'
import { GlobalContext } from '../Componentes/GlobalContext'
import Modal from '../Componentes/Modal'
function EditaDados() {
  const { profile, servidor } = React.useContext(GlobalContext)
  const [nome, setNome] = React.useState('')
  const [sobrenome, setSobrenome] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [idUsuario, setIdUsuario] = React.useState('')
  const [sub, setSub] = React.useState('')
  const [confirma, setConfirma] = React.useState('')
  const [mostrarModal, setMostrarModal] = React.useState(false)
  const [msg, setMsg] = React.useState(false)

  async function usuarioDados() {
    await axios.get(`${servidor}` + "/usuarios/" + profile.userId, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => {
      if (res.data.id === profile.userId) {
        setIdUsuario(res.data.id)
        setNome(res.data.nome)
        setSobrenome(res.data.sobrenome)
        setEmail(res.data.email)
      }
    })
      .catch((error) => console.log(error))
  }
  function handleClickAlterar(event) {
    event.preventDefault()
    const infoUsuarios = new FormData()

    infoUsuarios.append('nome', nome)
    infoUsuarios.append('sobrenome', sobrenome)
    infoUsuarios.append('email', email)

    axios.put(`${servidor}` + "/usuario/" + idUsuario, infoUsuarios, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => {
      if (res.status === 200) {
        window.scrollTo(0, 0)
        setMsg(res.data)
        setMostrarModal(true)
      }
    })
  }
  async function handleClickAlterarSenha(event) {
    event.preventDefault()
    if (sub !== confirma) {
      window.scrollTo(0, 0)
      setMsg("As senhas não conferem")
      setMostrarModal(true)
    }
    if (sub.length < 8 && confirma.length < 8) {
      window.scrollTo(0, 0)
      setMsg("A senha tem que conter no minimo 8 caracteres")
      setMostrarModal(true)
    }
    const subForm = new FormData()

    subForm.append('sub', sub)

    await axios.put(`${servidor}` + "/usuarios/" + idUsuario, subForm, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => {
      window.scrollTo(0, 0)
      setMsg(res.data)
      setMostrarModal(true)
    }).catch((error) => console.log(error))
  }

  React.useEffect(() => {
    usuarioDados()
  }, [])

  return (
    <div className='row'>
      <Menu />
      <div className='col-9'>
        <h1>Meus dados</h1>
        <hr />
        <form className="row">
          {mostrarModal && <Modal msg={msg} onClose={() => setMostrarModal(false)} />}
          <div className='col-4 ativo'>
            <CampoInput label="Nome" className="form-control" value={nome} setValue={setNome} />
          </div>
          <div className='col-8 ativo'>
            <CampoInput label="Sobrenome" className="form-control" value={sobrenome} setValue={setSobrenome} />
          </div>
          <div className='col-8 ativo'>
            <CampoInput label="Email" className="form-control" value={email} setValue={setEmail} />
          </div>
          <div className='col-2'>
            <Botao nome="Alterar" className="btn btn-primary mt-4" onClick={handleClickAlterar} />
          </div>
        </form>
        <h3 className="mt-5">Mudar senha</h3>
        <hr />
        <form className="mt-2">
          <div className='col-4 ativo'>
            <CampoInput type="password" label="Senha" className="form-control" value={sub} setValue={setSub} />
          </div>
          <div className='col-4 ativo'>
            <CampoInput type="password" label="Confirmar" className="form-control" value={confirma} setValue={setConfirma} />
          </div>
          <div className='col-4'>
            <Botao nome="Alterar" className="btn btn-primary mt-4" onClick={handleClickAlterarSenha} />
          </div>
        </form>
      </div>
    </div>
  )
}
export default EditaDados