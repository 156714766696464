import React from 'react'
import { useNavigate } from 'react-router'
import { GlobalContext } from '../Componentes/GlobalContext'
import Menu from './Menu'
import CampoInput from '../Componentes/CampoInput'
import CampoSelectCasaInput from '../Componentes/CampoSelectCasaInput'
import Botao from '../Componentes/Botao'
import Modal from '../Componentes/Modal'
import axios from 'axios'
function AddUsuario() {
  const { profile, servidor } = React.useContext(GlobalContext)
  const navegacao = useNavigate();
  const [nome, setNome] = React.useState('');
  const [sobrenome, setSobrenome] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [casas, setCasas] = React.useState('');
  const [sub, setSub] = React.useState('');
  const [subConfirma, setSubConfirma] = React.useState('');
  const [mostrarModal, setMostrarModal] = React.useState(false)
  const [msg, setMsg] = React.useState(false)
  const [carregando, setCarregando] = React.useState(false)
  const [usuarios, setUsuarios] = React.useState(null)
  if (profile.casa !== 1) {
    navegacao('/perfil')
  }
  function handleSubmit(event) {
    event.preventDefault()
    setCarregando(true)
    const adicionaUsuario = new FormData()

    adicionaUsuario.append('nome', nome)
    adicionaUsuario.append('sobrenome', sobrenome)
    adicionaUsuario.append('email', email)
    adicionaUsuario.append('sub', sub)
    adicionaUsuario.append('id_casas', casas)
    if (sub.length < 8) {
      window.scrollTo(0, 0)
      setMsg('A senha tem que ser maior que 8 digitos')
      setMostrarModal(true)
      return null
    }
    if (sub !== subConfirma) {
      window.scrollTo(0, 0)
      setMsg("As senhas não conferem, tente novamente.")
      setMostrarModal(true)
      return null
    }
    axios.post(`${servidor}` + "/usuarios", adicionaUsuario, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => {
      if (res.status === 201) {
        window.scrollTo(0, 0)
        setMsg('Novo usuário adicionado com sucesso!')
        setMostrarModal(true)
        setCarregando(false)
      }
    })
      .catch((error) => console.log(error))
  }

  async function pegaUsuarios() {
    await axios.get(`${servidor}` + "/usuarios", {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => {
      setUsuarios(res.data)
    }).catch((error) => console.log(error))
  }

  async function handleClick(event) {
    event.preventDefault()
    const idUsuario = event.target.parentNode.parentNode.children[0].getAttribute('id')
    await axios.delete(`${servidor}` + "/usuario/" + idUsuario, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => {
      console.log(res)
      if (res.status === 200) {
        window.scrollTo(0, 0)
        setMsg(res.data)
        setMostrarModal(true)
        setCarregando(false)
      }
    }).catch((error) => console.log(error))
    console.log(idUsuario)

  }
  React.useEffect(() => {
    pegaUsuarios()
  }, [mostrarModal])

  return (
    <div className='row'>
      <Menu />
      <div className='col-9'>
        <h1>Adicionar Usuário</h1>
        <hr />
        {mostrarModal && <Modal msg={msg} onClose={() => setMostrarModal(false)} />}
        <form className='row' onSubmit={handleSubmit}>
          <div className="col-4">
            <CampoInput type="text" label="Nome" className="form-control col-2" value={nome} setValue={setNome} required />
          </div>
          <div className="col-8">
            <CampoInput type="text" label="Sobrenome" className="form-control" value={sobrenome} setValue={setSobrenome} required />
          </div>
          <div className="col-8">
            <CampoInput type="email" label="Email" className="form-control" value={email} setValue={setEmail} required />
          </div>
          <div className="col-4">
            <CampoSelectCasaInput label="Casa" className='form-control' name="casa" value={casas} setValue={setCasas} required />
          </div>
          <div className="col-6">
            <CampoInput type="password" label="Senha" className="form-control" value={sub} setValue={setSub} required />
          </div>
          <div className="col-6">
            <CampoInput type="password" label="Confirmar senha" className="form-control" value={subConfirma} setValue={setSubConfirma} required />
          </div>
          <div className="col-12 text-end">
            <Botao nome="Adicionar" className="btn btn-primary mt-3" />
          </div>
          {carregando && <p className='text-success text-center border border-success mt-5 fw-bold'>Carregando...</p>}
          <div>
            <table className='table table-striped mt-5'>
              <thead>
                <th>#</th>
                <th>Nome</th>
                <th>Usuário</th>
                <th>Casa</th>
                <th>Acões</th>
              </thead>
              <tbody>
                {usuarios && usuarios.map((usuario) => {
                  return (
                    <>
                      <tr>
                        <td id={usuario.id}>{usuario.id}</td>
                        <td>{usuario.nome + " " + usuario.sobrenome}</td>
                        <td>{usuario.email}</td>
                        <td>{usuario.id_casas}</td>
                        <td>
                          <button className='btn btn-danger' onClick={handleClick}>Excluir</button>
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddUsuario