import React from 'react'
import axios from 'axios'
import { GlobalContext } from '../Componentes/GlobalContext'


function Saldo() {
  const { profile, servidor } = React.useContext(GlobalContext);
  const [valores, setValores] = React.useState([])
  const [saldo, setSaldo] = React.useState(null)


  async function pegaEntrada() {
    await axios.get(`${servidor + "/prestacoes/" + profile.casa}`, {
      headers: {
        'Authorization': `token ${profile.token}`
      }
    })
      .then((res) => setValores(res.data))
      .catch((error) => console.log(error))

    const entradas = valores.filter((valor) => valor.tipo === 'ENTRADA').map((item) => item.valor)
    const entradaFiltro = entradas.map((item) => item.replaceAll('.', ''))
    const entradaNumero = entradaFiltro.map((item) => item.replace(',', '.'))
    const entradaFinal = entradaNumero.map((item) => Number(item))
    const entradaSaldo = entradaFinal.reduce((a, b) => (a + b), 0)

    const saidas = valores.filter((valor) => valor.tipo === 'SAIDA').map((item) => item.valor)
    const saidaFiltro = saidas.map((item) => item.replaceAll('.', ''))
    const saidaNumero = saidaFiltro.map((item) => item.replace(',', '.'))
    const saidaFinal = saidaNumero.map((item) => Number(item))
    const saidaSaldo = saidaFinal.reduce((a, b) => (a + b), 0)

    setSaldo(entradaSaldo - saidaSaldo);
  }

  const saldoBanco = saldo && saldo.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  React.useEffect(() => {
    pegaEntrada()
  }, [saldo])

  return (
    <>
      <p className='fs-3 text-center col-3 bg-info rounded-bottom p-1'>
        <span className='text-white'>Saldo Banco:<p>{saldoBanco}</p> </span>
      </p>
    </>
  )
}

export default Saldo