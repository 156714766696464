import React from 'react'

export const useFetch = () => {
const [data, setData] = React.useState(null)
const [loading, setLoading ]= React.useState(null)
const [error, setError ]= React.useState(null)
const profile = JSON.parse(localStorage.getItem('profile'))

let response;
let json;

async function  request(url, method, dados) {
  let options = {
    method: method,
    body: dados,
    headers: { 'Authorization': `token ${profile.token}` }
  }
  try {
    setLoading(true)
    response = await fetch(url, options, dados)
    json = await response.json()
    setData(json)
} catch (error) {
  setError('Algo deu errado.')
} finally {
  setLoading(false)
  return {response, json}
}
}
  return {request, data, error, loading, response}
}
