import React from 'react'
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { GlobalContext } from '../Componentes/GlobalContext'
import '../Css/Modal.css'
function Menu() {

  const { profile } = React.useContext(GlobalContext)
  const navegacao = useNavigate();
  function handleClick(event) {
    event.preventDefault()
    localStorage.clear()
    window.location.reload()
  }
  React.useEffect(() => {
    if (localStorage.getItem('profile') === null)
      navegacao('/')
  }, [])

  return (
    <>
      <nav className='nav flex-column col-3' style={{ backgroundColor: ' #e3f2fd', height: '100vh' }}>
        <NavLink to={'/perfil'} className='link-menu'>Perfil</NavLink>
        <NavLink to={'/movimento'} className='link-menu'>Movimento Banco</NavLink>
        <NavLink to={'/movimentocaixa'} className='link-menu'>Movimento Caixa</NavLink>
        <NavLink to={'/origemtransferencias'} className='link-menu'>Transferências</NavLink>
        <NavLink to={'/relatorioCasa'} className='link-menu'>Relátorio</NavLink>
        <NavLink to={'/pendencias'} className='link-menu'>Pendências</NavLink>
        {profile.casa === 1 ? <NavLink to={'/administracao'} className='link-menu'>Administração</NavLink> : null}
        <div className='container'>
          <hr />
          <button className='btn btn-outline-primary' onClick={handleClick}>
            <i className="bi bi-box-arrow-left"></i>{' '}Sair
          </button>
        </div>
      </nav>
    </>
  )
}

export default Menu