import React from 'react';
import { GlobalContext } from '../Componentes/GlobalContext'
import CampoInput from '../Componentes/CampoInput';
import CampoAnexo from '../Componentes/CampoAnexo';
import Botao from '../Componentes/Botao';
import axios from 'axios';
import CampoSelectCasas from '../Componentes/CampoSelectCasas';
import Menu from '../Pages/Menu'
import Modal from '../Componentes/Modal';
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min.js';

function TransBanco() {
  const { profile, servidor, fraternidade } = React.useContext(GlobalContext);
  const [mostrarModal, setMostrarModal] = React.useState(false)
  const [msg, setMsg] = React.useState('')
  const [carregando, setCarregando] = React.useState(false)
  const [casas, setCasas] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [data, setData] = React.useState('');


  async function handleSubmit(event) {
    event.preventDefault()
    setCarregando(true)
    const valor = document.getElementById('valor').value
    const transacao = {
      origem: profile.casa,
      destino: '',
      id: ''
    }

    if (fraternidade[casas].nome.slice(-5) === 'CAIXA') {
      const anexo = document.querySelector('#anexo').files[0]
      const novoNome = Date.now() + "--" + anexo.name;
      const formEntrada = new FormData();
      formEntrada.append("tipo", 'ENTRADA')
      formEntrada.append("descricao", fraternidade[profile.casa].nome + " ==> " + fraternidade[casas].nome + " || " + descricao)
      formEntrada.append("data", data)
      formEntrada.append("valor", valor)
      formEntrada.append("anexo", anexo, novoNome)
      formEntrada.append("transferencia", 'TRANSFERENCIA')
      formEntrada.append("id_usuarios", profile.userId)
      formEntrada.append("id_casas", casas - 1)

      await axios.post(`${servidor + "/caixas/"}`, formEntrada, {
        headers: {
          'Authorization': `token ${profile.token}`
        }
      }).then((res) => {
        transacao.id = res.data.id
        transacao.destino = fraternidade[casas].nome

        const formSaida = new FormData();
        formSaida.append("tipo", 'SAIDA')
        formSaida.append("descricao", fraternidade[profile.casa].nome + " ==> " + fraternidade[casas].nome + " || " + descricao)
        formSaida.append("data", data)
        formSaida.append("valor", valor)
        formSaida.append("anexo", anexo, novoNome)
        formSaida.append("transferencia", JSON.stringify(transacao))
        formSaida.append("id_usuarios", profile.userId)
        formSaida.append("id_casas", profile.casa)

        axios.post(`${servidor + "/prestacoes/"}`, formSaida, {
          headers: {
            'Authorization': `token ${profile.token}`
          }
        }).then((res) => {
          if (res.status === 201) {
            window.scrollTo(0, 0)
            setMostrarModal(true)
            setMsg("Transferência feita com sucesso!!!")
            setCarregando(false)
          }
        })
      }).catch((error) => console.log(error))

    }

    if (fraternidade[casas].nome.slice(-5) === 'BANCO') {
      const anexo = document.querySelector('#anexo').files[0]
      const novoNome = Date.now() + "--" + anexo.name;
      const formEntrada = new FormData();
      formEntrada.append("tipo", 'ENTRADA')
      formEntrada.append("descricao", fraternidade[profile.casa].nome + " ==> " + fraternidade[casas].nome + " || " + descricao)
      formEntrada.append("data", data)
      formEntrada.append("valor", valor)
      formEntrada.append("anexo", anexo, novoNome)
      formEntrada.append("transferencia", 'TRANSFERENCIA')
      formEntrada.append("id_usuarios", profile.userId)
      formEntrada.append("id_casas", casas)

      await axios.post(`${servidor + "/prestacoes/"}`, formEntrada, {
        headers: {
          'Authorization': `token ${profile.token}`
        }
      }).then((res) => {
        transacao.id = res.data.id
        transacao.destino = fraternidade[casas].nome
        const formSaida = new FormData();
        formSaida.append("tipo", 'SAIDA')
        formSaida.append("descricao", fraternidade[profile.casa].nome + " ==> " + fraternidade[casas].nome + " || " + descricao)
        formSaida.append("data", data)
        formSaida.append("valor", valor)
        formSaida.append("anexo", anexo, novoNome)
        formSaida.append("transferencia", JSON.stringify(transacao))
        formSaida.append("id_usuarios", profile.userId)
        formSaida.append("id_casas", profile.casa)

        axios.post(`${servidor + "/prestacoes/"}`, formSaida, {
          headers: {
            'Authorization': `token ${profile.token}`
          }
        }).then((res) => {
          if (res.status === 201) {
            window.scrollTo(0, 0)
            setMostrarModal(true)
            setMsg("Transferência feita com sucesso!!!")
            setCarregando(false)
          }
        })
      }).catch((error) => console.log(error))
    }
  }
  async function handleExcluir(event) {
    event.preventDefault()
    const idTransferencia = event.target.parentNode.parentNode.children[0].getAttribute('id')
    await axios.get(`${servidor + "/prestacao/" + idTransferencia}`, {
      headers: {
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => {
      const objTransacao = res.data.transferencia
      const idExcluir = JSON.parse(objTransacao)
      if (idExcluir.destino.slice(-5) === 'CAIXA') {
        excluir('prestacoes', idTransferencia)
        excluir('caixas', idExcluir.id)
      }
      if (idExcluir.destino.slice(-5) === 'BANCO') {
        excluir('prestacoes', idTransferencia)
        excluir('prestacoes', idExcluir.id)
      }
      window.scrollTo(0, 0)
      setMostrarModal(true)
      setMsg("Transferência excluída com sucesso!!!")
      setCarregando(false)
    })
      .catch((error) => console.log(error))
  }

  async function excluir(tabela, id) {
    await axios.delete(`${servidor + "/" + tabela + "/" + id}`, {
      headers: {
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => console.log(res.status))
      .catch((error) => console.log(error))
  }

  const [transferencia, setTransferencia] = React.useState(null)
  async function transferencias() {
    await axios.get(`${servidor + "/prestacoes/" + profile.casa}`, {
      headers: {
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => setTransferencia(res.data))
      .catch((error) => console.log(error))
  }
  $('#valor').mask('000.000.000.000.000,00', { reverse: true });
  React.useEffect(() => {
    transferencias()
  }, [mostrarModal])

  return (
    <div className='row'>
      <Menu />
      <form className='col-9 ' onSubmit={handleSubmit}>
        <div className='row'><h1 className='col-9'>Origem : Banco </h1></div>
        <hr />
        <div className='row'>
          <div className='col-4'>
            <CampoSelectCasas label="Destino" className='form-control' name="destino" value={casas} setValue={setCasas} required />
          </div>
          <div className='col-4'>
            <CampoInput type="date" id="data" name="data" label="Data" value={data} setValue={setData} className='form-control' required />
          </div>
          <div className='col-4'>
            <label>Valor</label>
            <input id="valor" className='form-control' placeholder="0,00" required />
          </div>
          <div className='col-12'>
            <CampoInput type="text" id="descricao" name="descricao" label="Mensagem" value={descricao} setValue={setDescricao} className='form-control' required />
          </div>
          <div className='col-10'>
            <CampoAnexo id="anexo" name="anexo" accept=".pdf, .jpeg, .jpg, .png" label="Anexo" className='form-control anexo' required />
          </div>
          <div className='col-2'>
            <br />
            <Botao nome="Transferir" className='btn btn-primary' />
          </div>
        </div>
        {carregando && <p className='text-success text-center border border-success mt-5 fw-bold'>Carregando...</p>}
        {mostrarModal && <Modal msg={msg} onClose={() => setMostrarModal(false)} />}
        <div className='col-12'>
          <table className='table table-striped' style={{ marginTop: '40px' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Tipo</th>
                <th>Data</th>
                <th>Mensagem</th>
                <th>Valor</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {transferencia && transferencia.filter((item) => item.transferencia !== 'st' && item.id_usuarios === profile.userId).map((item) => {
                return (
                  <tr key={item.id}>
                    <td id={item.id}>{item.id}</td>
                    <td className='tipo'>TRANSFERÊNCIA/SAQUE</td>
                    <td>{item.data.split('-').reverse().join('/')}</td>
                    <td>{item.descricao}</td>
                    <td className='valor'>{item.valor}</td>
                    <td className='valor'><button className='btn btn-outline-danger' onClick={handleExcluir}>Excluir</button></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </form>
    </div>
  )
}

export default TransBanco