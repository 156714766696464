import React from 'react'

function CampoSelect({ options, value, setValue, label, ...props }) {
  return (
    <>
      <label>{label}</label>
      <select value={value} onChange={({ target }) => setValue(target.value)} {...props}>
        <option value="" disabled>SELECIONE...</option>
        {options.map((item) => (<option key={item} value={item}>{item}</option>))}
      </select>
    </>
  )
}

export default CampoSelect