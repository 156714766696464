import React from 'react'
import Menu from './Menu'
import { useFetch } from '../Hooks/useFetch.js'
import { GlobalContext } from '../Componentes/GlobalContext'
import CampoSelectCasas from '../Componentes/CampoSelectCasas';
import CampoInput from '../Componentes/CampoInput';
import { useNavigate } from 'react-router'
function Movimentos() {
    const { servidor, profile } = React.useContext(GlobalContext);
    const {request,data, error, loading, response } = useFetch();
    const [dados, setDados] = React.useState(null)
    const [casa, setCasa] = React.useState('')
    const [periodo, setPeriodo] = React.useState(null)
    const [fraternidade, setFraternidade ] = React.useState('')
    const [editaInput, setEditaInput] = React.useState(false)
    const [categoria, setCategoria] = React.useState('')

    const navegacao = useNavigate()
async function downloadAnexo(event) {
    event.preventDefault()
    const idPrestacoes = event.target.parentNode.parentNode.children[0].getAttribute('id')
    const nomeAnexo = event.target.parentNode.getAttribute('name')
      await fetch(`${servidor + '/download/' + idPrestacoes}/${nomeAnexo}`, {
        headers: {
          'Authorization': `token ${profile.token}`
        }
      })
        .then(res => res.blob())
        .then(data => {
          let a = document.createElement("a")
          a.href = window.URL.createObjectURL(data)
          a.download = nomeAnexo;
          a.click();
        })
    }

 function buscar(event) {
    event.preventDefault()
    request(servidor + '/casas/'+ casa ).then((res) => {
        if(res.json.cob !== 'BANCO') {
            pegarMovimentos('/cob/' + (casa -1))
        } else {
            pegarMovimentos('/prestacoes/' + casa)
        }
        setFraternidade(res.json.nome)
        setEditaInput(true)
    })

 }
 function nomeCategoria(id) {
    let nomeCategoria = categoria.find((item) => item.id == id)
    return nomeCategoria.categoria
 }
 function pegarMovimentos(path) {
  console.log(path)
    request(servidor + path).then((res) => {
        setDados(res.json)
    })
 } 
 function filtroPeriodo(data) {
    return data.data.substring(0, 7) === periodo
  }
function limpaInput(event) {
    event.preventDefault()
    setEditaInput(false)
    setCasa('')
    setPeriodo('')
    setFraternidade('')
    setDados('')
}
function editar(event) {
  event.preventDefault()
  const idMovimento = event.target.getAttribute('id')

  if(fraternidade.includes('BANCO')) {
    localStorage.setItem('movimento','/prestacao/')
    navegacao('/editaMovimentosAdm/?' + idMovimento)
  }
  if(fraternidade.includes('CAIXA')) {
    localStorage.setItem('movimento','/caixa/')
    navegacao('/editaMovimentosAdm/?' + idMovimento)
  }

}
React.useEffect(() => {
    request(servidor + '/categorias/').then((res) => setCategoria(res.json))
    if (profile.casa !== 1) {
      navegacao('/perfil')
    }
  },[])
 if(error) return <p>{error}</p>
 if(loading) return <p>Carregando...</p>
    return (
      <div className='row'>
      <Menu />
      <div className='col-9'>
        <h1>Movimentos</h1>
        <hr />
        <form className='row' onSubmit={buscar}>
        <div className='col-4'>
            <CampoSelectCasas value={casa} setValue={setCasa} className="form-control" label="Casas" required disabled={`${editaInput === true ? 'true':''}`} />
        </div>
        <div className='col-4'>
            <CampoInput type="month" className="form-control" value={periodo} setValue={setPeriodo} label="Periodo" required disabled={`${editaInput === true ? 'true':''}`} />
          </div>
          <div className='col-2'>
            <br />
            <button className='btn btn-primary'>Buscar</button>
          </div>
          <div className='col-2 text-end'>
            <br />
            <button className='btn btn-success' onClick={limpaInput}>Limpar</button>
          </div>
          </form>
          <div className='col-12'>
          {fraternidade && <h3 className='mt-5'>{fraternidade}</h3>}
            <table className='table table-striped' style={{ marginTop: '40px' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Tipo</th>
                  <th>Data</th>
                  <th>Fornecedor</th>
                  <th>Categoria</th>
                  <th>Descrição</th>
                  <th>Valor</th>
                  <th>Editar</th>
                  <th>Anexo</th>

                </tr>
              </thead>
              <tbody>
                {dados && dados.filter(filtroPeriodo).map((item) => {
                  return (
  
                    <tr key={item.id}>
                      <td id={item.id}>{item.id}</td>
                      <td className='tipo'>{item.tipo}</td>
                      <td>{item.data.split('-').reverse().join('/')}</td>
                      <td>{item.fornecedor}</td>
                      <td>{
                        
                      item.categoria === null || item.categoria === undefined ? '': `${nomeCategoria(item.categoria)}`
                      }</td>
                      <td>{item.descricao}</td>
                      <td className='valor'>{item.valor}</td>
                      <td><button className="btn btn-outline-primary btn-sm" id={item.id} onClick={editar}>Editar</button></td>
                      <td name={item.anexo}><button className="btn btn-outline-success btn-sm" onClick={downloadAnexo}>Download</button></td>
                    </tr>
                  )
                })}
              </tbody>
  
            </table>
  
          </div>
          </div>
          </div>
    )
}

export default Movimentos