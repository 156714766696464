import React from 'react'
import Menu from './Menu'
import CampoSelect from '../Componentes/CampoSelect.js'
import CampoInput from '../Componentes/CampoInput'
import Botao from '../Componentes/Botao'
import { GlobalContext } from '../Componentes/GlobalContext'

function Categorias() {
    const [tipo, setTipo] = React.useState('')
    const [categoria, setCategoria] = React.useState('')
    const [categorias, setCategorias] = React.useState(null)
    const [modal, setModal] = React.useState(false)
    const [msg, setMsg] = React.useState('')
    const { profile, servidor } = React.useContext(GlobalContext)

async function listacategoria(){
    const requisicao = await fetch(servidor + '/categorias',{
        method: 'GET',
        headers: { 'Authorization': `token ${profile.token}` }
      })
    const resposta =  await requisicao.json()
    setCategorias(resposta)

}
async   function handleClickSalvar(event){
        event.preventDefault()
        const formCategoria = new FormData()
        formCategoria.append('tipo', tipo)
        formCategoria.append('categoria', categoria)

        const requisicao = await fetch(servidor + '/categoria',{
            method: 'POST',
            body: formCategoria,
            headers: { 'Authorization': `token ${profile.token}` }
          })
        const resposta =  await requisicao.json()
        const status =  await requisicao
        console.log(resposta)
        if(status.ok) {
            setCategoria('')
            setTipo('')
            setModal(true)
            setMsg(resposta)
            setTimeout(() => {
                setModal(false)
              }, 2000);
        }
    }
  async  function handleEditar(event) {
        event.preventDefault()
        const id = event.target.parentNode.parentNode.parentNode.getAttribute('id')
        const requisicao = await fetch(servidor + '/categoria/' + id,{
            method: 'DELETE',
            headers: { 'Authorization': `token ${profile.token}` }
          })
        const resposta =  await requisicao.json()
        const status =  await requisicao
        console.log(resposta)
        if(status.ok) {
            setCategoria('')
            setTipo('')
            setModal(true)
            setMsg(resposta)
            setTimeout(() => {
                setModal(false)
              }, 2000);
        }
    }
React.useEffect(() => {
        listacategoria()
      }, [modal])
  return (
    <div className='row'>
        <Menu />
        
            <form className='col-9' id="formPrestacoes" onSubmit={handleClickSalvar}>
            
            <h1>Categorias</h1>
            <div className='row'>
            <div className='col-2'>
                <CampoSelect options={['ENTRADA', 'SAIDA']} label="Tipo" id="tipo" value={tipo} setValue={setTipo} name="tipo" className='form-control' required />
            </div>
            <div className='col-8'>
            <CampoInput label="Descrição" className='form-control' value={categoria} setValue={setCategoria} required/>
            </div>
            <div className='col-2'>
                <br />
                <Botao nome="Salvar" className="btn btn-primary text-end" />
            </div>
            </div>
            <br />
            
            <div className='col-9 table-responsive'>
            <table class="table table-hover">
                <thead>
                    <th>Tipo</th>
                    <th>Categoria</th>
                    <th>Excluir</th>
                </thead>
                <tbody>
                {categorias && categorias.map((item) => {
                    return (
                        <tr id={item.id}>
                            <td>{item.tipo}</td>
                            <td>{item.categoria}</td>
                            <td><button className='btn btn-danger' onClick={handleEditar}><i className="bi bi-bookmark-x"></i></button></td>
                        </tr>
                    )
                })}
                </tbody>
                </table>
            </div>
            </form>
            
    </div>
    
  )
}

export default Categorias