import React from 'react'
import logoProv from '../imagens/logoProv.png'

function Header() {
  return (
    <div className=' bg-info p-3' style={{ borderBottom: '3px solid #1a68b6' }}>
      <img src={logoProv} className='mx-auto d-block' style={{ width: '40%' }} alt="Logo Província | Logo FIMDA" />
    </div>
  )
}
export default Header