import React from 'react'
import Menu from './Menu'
import { useFetch } from '../Hooks/useFetch';
import { GlobalContext } from '../Componentes/GlobalContext'
import CampoSelect from '../Componentes/CampoSelect';
import CampoSelectCategorias from '../Componentes/CampoSelectCategorias';
import CampoInput from '../Componentes/CampoInput';
import Botao from '../Componentes/Botao';
import { useNavigate } from 'react-router';

function EditaMovimento() {
    const url = window.location.href;
    const selecao = localStorage.getItem('movimento')
    const idMovimento = url.substring(url.indexOf('?') + 1)
    const { servidor, profile } = React.useContext(GlobalContext);
    const {request, data, response, error, loading} = useFetch()
    const [tipo, setTipo] = React.useState()
    const [categorias, setCategorias] = React.useState()
    const [fornecedor, setFornecedor] = React.useState()
    const [descricao, setDescricao] = React.useState()
    const [valor, setValor] = React.useState()
    const [dataMovimento, setDataMovimento] = React.useState()
    const navigate = useNavigate()
function updateCategoria() {
    const categoria = new FormData()
    categoria.append('categoria', categorias)
    async function update(){
    const {response} = await request(servidor + selecao + idMovimento ,'PUT', categoria)
    if(response.ok) {
        navigate('/pendencias')
    }
    }
    update()
}
React.useEffect(() => {
    async function fetchData(){
    const {response, json} = await request(servidor + selecao + idMovimento)
    console.log(response)
    setTipo(json.tipo)
    setCategorias(1)
    setDataMovimento(json.data)
    setValor(json.valor)
    setFornecedor(json.fornecedor)
    setDescricao(json.descricao)
    console.log(typeof(selecao))
    }
    fetchData()
},[])
if(error) return <p>{error}</p>
if(loading) return <p>Carregando...</p>
if(data)
  return (
    <div className='row'>
    <Menu />
        <div className='col-9'>
            <div className='row'>
        <div className='col-2'>
            <CampoSelect options={['ENTRADA', 'SAIDA']} label="Tipo" id="tipo" value={tipo} setValue={setTipo} name="tipo" className='form-control' required disabled/>
          </div>
          <div className='col-4'>
            <CampoSelectCategorias value={categorias} setValue={setCategorias} tipo={tipo} label='Categorias' className='form-control' />
          </div>
          <div className='col-3'>
            <CampoInput type="date" id="data" name="data" label="Data" value={dataMovimento} setValue={setDataMovimento} className='form-control' required disabled/>
          </div>
          <div className='col-3'>
            <label>Valor</label>
            <input id="valor" className='form-control' value={valor}placeholder="0,00" required disabled/>
          </div>
          <div className='col-6'>
            <CampoInput type="text" id="fornecedor" name="fornecedor" label="Fornecedor" value={fornecedor} setValue={setFornecedor} required className='form-control' disabled/>
          </div>
          <div className='col-6'>
            <CampoInput type="text" id="descricao" name="descricao" label="Descrição" value={descricao} setValue={setDescricao} required className='form-control' disabled/>
          </div>
          <div className='col-12 text-end'>
            <br />
            <Botao nome="Atualizar" id="Atualizar" className='btn btn-primary' onClick={updateCategoria} />
          </div>
        </div>
    </div>
    </div>
  )
  else return null
}

export default EditaMovimento