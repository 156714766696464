import React from 'react'
import { useNavigate } from 'react-router'
import { GlobalContext } from '../Componentes/GlobalContext'
import Menu from './Menu'
import CampoInput from '../Componentes/CampoInput'
import Botao from '../Componentes/Botao'
import Modal from '../Componentes/Modal'
import axios from 'axios'
function AddCasa() {
  const { profile, servidor } = React.useContext(GlobalContext)
  const [nome, setNome] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [mostrarModal, setMostrarModal] = React.useState(false)
  const [msg, setMsg] = React.useState(false)
  const [dadosTabela, setDadosTabela] = React.useState(false)
  const navegacao = useNavigate();

  if (profile.casa !== 1) {
    navegacao('/perfil')
  }
  function handleSubmit(event) {
    event.preventDefault()
    const addCasa = new FormData()
    addCasa.append('nome', nome + " - BANCO")
    addCasa.append('descricao', descricao)
    addCasa.append('cob', 'BANCO')
    axios.post(`${servidor}` + "/casas", addCasa, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => {
      if (res.status === 201) {
        const addCaixa = new FormData()
        addCaixa.append('nome', nome + " - CAIXA")
        addCaixa.append('descricao', descricao)
        addCaixa.append('cob', res.data.id)

        axios.post(`${servidor}` + "/casas", addCaixa, {
          headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `token ${profile.token}`
          }
        }).then((res) => {
          if (res.status === 201) {
            window.scrollTo(0, 0)
            setMsg('Banco e Caixa criados com sucesso!')
            setMostrarModal(true)
          }
        }).catch((error) => console.log(error))
      }
    }).catch((error) => console.log(error))
  }
  async function tabelaCasas() {
    await axios.get(`${servidor}` + "/casas", {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => {
      setDadosTabela(res.data)
    }).catch((error) => console.log(error))
  }
  function excluirCasa(event) {
    event.preventDefault()
    const idBanco = event.target.parentNode.parentNode.children[0].getAttribute('id')
    axios.delete(`${servidor}` + "/casa/" + idBanco, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => {
      if (res.status === 200) {
        window.scrollTo(0, 0)
        setMsg('Caixa/Banco foram excluidos com sucesso')
        setMostrarModal(true)
      }
    }).catch((error) => console.log(error))
  }
  React.useEffect(() => {
    tabelaCasas()
  }, [mostrarModal])
  return (
    <div className='row'>
      <Menu />
      <div className='col-9'>
        <h1>Adicionar Casa</h1>
        <hr />
        {mostrarModal && <Modal msg={msg} onClose={() => setMostrarModal(false)} />}
        <form className='row' onSubmit={handleSubmit}>
          <div className="col-4">
            <CampoInput type="text" label="Nome da Casa" className="form-control col-2" value={nome} setValue={setNome} required />
          </div>
          <div className="col-8">
            <CampoInput type="text" label="Descrição" className="form-control" value={descricao} setValue={setDescricao} required />
          </div>
          <div className="col-12 text-end">
            <Botao nome="Adicionar" className="btn btn-primary mt-3" />
          </div>
          <div>
            <table className='table table-striped mt-5'>
              <thead>
                <th>#</th>
                <th>Nome</th>
                <th>Descrição</th>
                <th>Acões</th>
              </thead>
              <tbody>
                {dadosTabela && dadosTabela.map((item) => {
                  return (
                    <>
                      <tr key={item.id}>
                        <td id={item.id}>{item.id}</td>
                        <td>{item.nome}</td>
                        <td>{item.descricao}</td>
                        <td><button className='btn btn-outline-danger' onClick={excluirCasa}>Excluir</button></td>
                      </tr>
                    </>
                  )
                })}

              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddCasa