import React from 'react'
import '../Css/Modal.css'
function Modal({ msg, onClose }) {

  return (
    <div className='modalContainer'>
      <div className='modalMsgAviso'>
        <h2 className='text-center'><i className="bi bi-info-circle"></i>{' '}Aviso</h2>
        <hr />
        <br />
        <p className='text-center'>{msg}</p>
        <br />
        <hr />
        <div className='d-flex justify-content-center'>
          <button className='btn btn-outline-info btn-lg' onClick={onClose} >Fechar</button>
        </div>
      </div>
    </div>
  )
}

export default Modal