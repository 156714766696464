import React from 'react'
import axios from 'axios'
import { GlobalContext } from '../Componentes/GlobalContext'
import CampoSelectCasaInput from '../Componentes/CampoSelectCasaInput'
import Botao from '../Componentes/Botao'
import Menu from '../Pages/Menu'
import CampoInput from '../Componentes/CampoInput'
import CriarPdf from '../Componentes/Relatorio'

import { useNavigate } from 'react-router'

function RelatorioAdm() {
  const { profile, servidor } = React.useContext(GlobalContext)
  const [casas, setCasas] = React.useState('')
  const [banco, setBanco] = React.useState(null)
  const [caixa, setCaixa] = React.useState(null)
  const [periodo, setPeriodo] = React.useState('')
  const navegacao = useNavigate();

  async function relatorio(event) {
    event.preventDefault()
    cob(casas)
    prestacoes(casas)
  }
  async function cob(id) {
    await axios.get(`${servidor + "/cob/" + id}`, {
      headers: {
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => setCaixa(res.data))
      .catch((error) => console.log(error))
  }
  async function prestacoes(id) {
    await axios.get(`${servidor + "/prestacoes/" + id}`, {
      headers: {
        'Authorization': `token ${profile.token}`
      }
    }).then((res) => setBanco(res.data))
      .catch((error) => console.log(error))
  }

React.useEffect(() => {
  if (profile.casa !== 1) {
    navegacao('/perfil')
  // setCasas('')
  // setPeriodo('')
  }
},[])
  return (
    <div className='row'>
      <Menu />
      <div className='col-9'>
        <h1>Relátorio</h1>
        <hr />
        <form  className='row' id="formulario" onSubmit={relatorio}>
          <div className='col-4'>
            <CampoSelectCasaInput className='form-control limpaInput' key="selectCasa" value={casas} setValue={setCasas} label="Casa" required />
          </div>
          <div className='col-3'>
            <CampoInput type="month" className="form-control limpaInput" value={periodo} setValue={setPeriodo} label="Periodo" required />
          </div>
          <div className='col-4'>
            <br />
            <Botao nome="Gerar" className="btn btn-primary text-end" onClick={CriarPdf(banco,caixa,periodo,casas)} />
          </div>

        </form>
      </div >
    </div >
  )
}

export default RelatorioAdm