import React from 'react'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { GlobalContext } from '../Componentes/GlobalContext'
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function CriarPdf(banco, caixa, periodo,casas) {
  const {fraternidade } = React.useContext(GlobalContext)

  function filtroPeriodoBanco(data) {
    return data.data.substring(0, 7) === periodo
  }

  function filtroPeriodoCaixa(data) {
    return data.data.substring(0, 7) === periodo
  }

  //saldo anterior
function saldoAnterior(saldo) {
 return  saldo.data < periodo
}

const listaEntrada = banco && banco.filter(saldoAnterior).filter((item) => item.tipo === 'ENTRADA').map((item) => item.valor)
const listaEntradaFiltrada = listaEntrada && listaEntrada.map((item) => item.replaceAll('.', ''))
const listaEntradaNumero = listaEntradaFiltrada && listaEntradaFiltrada.map((item) => item.replace(',', '.'))
const listaEntradaFinal = listaEntradaNumero && listaEntradaNumero.map((item) => Number(item))
const listaEntradaSaldo = listaEntradaFinal && listaEntradaFinal.reduce((a, b) => (a + b), 0)
// listaEntradaSaldo && listaEntradaSaldo.toLocaleString("pt-BR", {
//   minimumFractionDigits: 2,
//   maximumFractionDigits: 2
// })

const listaSaida = banco && banco.filter(saldoAnterior).filter((item) => item.tipo === 'SAIDA').map((item) => item.valor)
const listaSaidaFiltrada = listaSaida && listaSaida.map((item) => item.replaceAll('.', ''))
const listaSaidaNumero = listaSaidaFiltrada && listaSaidaFiltrada.map((item) => item.replace(',', '.'))
const listaSaidaFinal = listaSaidaNumero && listaSaidaNumero.map((item) => Number(item))
const listaSaidaSaldo = listaSaidaFinal && listaSaidaFinal.reduce((a, b) => (a + b), 0)
// listaSaidaSaldo && listaSaidaSaldo.toLocaleString("pt-BR", {
//   minimumFractionDigits: 2,
//   maximumFractionDigits: 2
// })
const bancoAnterior = listaEntradaSaldo - listaSaidaSaldo

const listaEntCaixa = caixa && caixa.filter(saldoAnterior).filter((item) => item.tipo === 'ENTRADA').map((item) => item.valor)
const listaEntCaixaFiltrada = listaEntCaixa && listaEntCaixa.map((item) => item.replaceAll('.', ''))
const listaEntCaixaNumero = listaEntCaixaFiltrada && listaEntCaixaFiltrada.map((item) => item.replace(',', '.'))
const listaEntCaixaFinal = listaEntCaixaNumero && listaEntCaixaNumero.map((item) => Number(item))
const listaEntCaixaSaldo = listaEntCaixaFinal && listaEntCaixaFinal.reduce((a, b) => (a + b), 0)
// listaEntCaixaSaldo && listaEntCaixaSaldo.toLocaleString("pt-BR", {
//   minimumFractionDigits: 2,
//   maximumFractionDigits: 2
// })

const listaSaiCaixa = caixa && caixa.filter(saldoAnterior).filter((item) => item.tipo === 'SAIDA').map((item) => item.valor)
const listaSaiCaixaFiltrada = listaSaiCaixa && listaSaiCaixa.map((item) => item.replaceAll('.', ''))
const listaSaiCaixaNumero = listaSaiCaixaFiltrada && listaSaiCaixaFiltrada.map((item) => item.replace(',', '.'))
const listaSaiCaixaFinal = listaSaiCaixaNumero && listaSaiCaixaNumero.map((item) => Number(item))
const listaSaiCaixaSaldo = listaSaiCaixaFinal && listaSaiCaixaFinal.reduce((a, b) => (a + b), 0)
// listaSaiCaixaSaldo && listaSaiCaixaSaldo.toLocaleString("pt-BR", {
//   minimumFractionDigits: 2,
//   maximumFractionDigits: 2
// })
const caixaAnterior = listaEntCaixaSaldo - listaSaiCaixaSaldo



//saldo anterior

  let movimentoBanco = banco && banco.filter(filtroPeriodoBanco).map((item) => {
    return [
      { text: item.data.split('-').reverse().join('/'), fontSize: 8 },
      { text: item.tipo, fontSize: 8 },
      { text: item.fornecedor, fontSize: 8 },
      { text: item.descricao, fontSize: 8 },
      { text: item.valor, fontSize: 8, alignment: 'right'  }
    ]
  })

  let movimentoCaixa = caixa && caixa.filter(filtroPeriodoCaixa).map((item) => {
    return [
      { text: item.data.split('-').reverse().join('/'), fontSize: 8 },
      { text: item.tipo, fontSize: 8 },
      { text: item.fornecedor, fontSize: 8 },
      { text: item.descricao, fontSize: 8 },
      { text: item.valor, fontSize: 8, alignment: 'right'  }
    ]
  })

//calcular banco

  const entradas = banco && banco.filter(filtroPeriodoBanco).filter((item) => item.tipo === 'ENTRADA').map((item) => item.valor)
  const entradaFiltro = entradas && entradas.map((item) => item.replaceAll('.', ''))
  const entradaNumero = entradaFiltro && entradaFiltro.map((item) => item.replace(',', '.'))
  const entradaFinal = entradaNumero && entradaNumero.map((item) => Number(item))
  const entradaSaldo = entradaFinal && entradaFinal.reduce((a, b) => (a + b), 0)
  const totalCredito = entradaSaldo && entradaSaldo.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  const saidas = banco && banco.filter(filtroPeriodoBanco).filter((item) => item.tipo === 'SAIDA').map((item) => item.valor)
  const saidaFiltro = saidas && saidas.map((item) => item.replaceAll('.', ''))
  const saidaNumero = saidaFiltro && saidaFiltro.map((item) => item.replace(',', '.'))
  const saidaFinal = saidaNumero && saidaNumero.map((item) => Number(item))
  const saidaSaldo = saidaFinal && saidaFinal.reduce((a, b) => (a + b), 0)
  const totalDebito = saidaSaldo && saidaSaldo.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  const somaPeriodo = entradaSaldo - saidaSaldo
  const totalPeriodo = somaPeriodo && somaPeriodo.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }) 

  //calcular banco

  //calcular caixa

  const entradasCaixa = caixa && caixa.filter(filtroPeriodoBanco).filter((item) => item.tipo === 'ENTRADA').map((item) => item.valor)
  const entradaFiltroCaixa = entradasCaixa && entradasCaixa.map((item) => item.replaceAll('.', ''))
  const entradaNumeroCaixa = entradaFiltroCaixa && entradaFiltroCaixa.map((item) => item.replace(',', '.'))
  const entradaFinalCaixa = entradaNumeroCaixa && entradaNumeroCaixa.map((item) => Number(item))
  const entradaSaldoCaixa = entradaFinalCaixa && entradaFinalCaixa.reduce((a, b) => (a + b), 0)
  const totalCreditoCaixa = entradaSaldoCaixa && entradaSaldoCaixa.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  const saidasCaixa = caixa && caixa.filter(filtroPeriodoBanco).filter((item) => item.tipo === 'SAIDA').map((item) => item.valor)
  const saidaFiltroCaixa = saidasCaixa && saidasCaixa.map((item) => item.replaceAll('.', ''))
  const saidaNumeroCaixa = saidaFiltroCaixa && saidaFiltroCaixa.map((item) => item.replace(',', '.'))
  const saidaFinalCaixa = saidaNumeroCaixa && saidaNumeroCaixa.map((item) => Number(item))
  const saidaSaldoCaixa = saidaFinalCaixa && saidaFinalCaixa.reduce((a, b) => (a + b), 0)
  const totalDebitoCaixa = saidaSaldoCaixa && saidaSaldoCaixa.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  const somaPeriodoCaixa = entradaSaldoCaixa - saidaSaldoCaixa
  const totalPeriodoCaixa = somaPeriodoCaixa && somaPeriodoCaixa.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }) 
  //calcular caixa

  if (movimentoBanco === null) return null
  if (movimentoCaixa === null) return null

  pdfMake.createPdf({
    pageOrientation: 'portrait',
    content: [
      {
        text:
          'RELATÓRIO POR PERIODO '+ fraternidade[casas].nome.slice(0, -7), margin: [0, 10], alignment: 'center',

      },
      {
        text:
          `Movimento Banco`, margin: [0, 20],
      },
      {
        text:`Saldo anterior: ${bancoAnterior.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`,margin: [0, 10], alignment:'right',fontSize: 8
      },
      {
        table: {
          headerRows: 1,
          widths: [55, 50, 100, '*', 65],
          body: [
            [
              { text: 'Data', style: 'tableHeader', fontSize: 7 },
              { text: 'Tipo', style: 'tableHeader', fontSize: 7 },
              { text: 'Fornecedor', style: 'tableHeader', fontSize: 7 },
              { text: 'Descrição', style: 'tableHeader', fontSize: 7 },
              { text: 'Valor', style: 'tableHeader', fontSize: 7}
            ],
            ...movimentoBanco,
          ],
        }, layout: 'lightHorizontalLines'
      },
      {
        text:
          '', margin: [0, 10],

      },
      {
        table: {
          widths: [430, 70],
          body: [
            [{text: 'CRÉDITO:', style: 'tableHeader', fontSize: 8, alignment: 'right',border: [false]},{text: totalCredito, fontSize: 8, alignment: 'right',border: [false]},],
            [{text: 'DÉBITO:', style: 'tableHeader', fontSize: 8, alignment: 'right',border: [false]},{text: totalDebito, fontSize: 8, alignment: 'right',border: [false]}],
            [{text: 'SALDO:', style: 'tableHeader', fontSize: 8, alignment: 'right',border: [false]},{text: totalPeriodo, fontSize: 8, alignment: 'right',border: [false]}],
          ],
        }, layout: 'lightHorizontalLines'
      }, {
        text:
          'Movimento Caixa',pageBreak: 'before', margin: [0, 20]
      },
      {
        text:`Saldo anterior: ${caixaAnterior.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`,margin: [0, 10], alignment:'right',fontSize: 8
      }, {
        table: {
          headerRows: 1,
          widths: [55, 50, 100, '*', 65],
          body: [
            [
              { text: 'Data', style: 'tableHeader', fontSize: 7 },
              { text: 'Tipo', style: 'tableHeader', fontSize: 7 },
              { text: 'Fornecedor', style: 'tableHeader', fontSize: 7 },
              { text: 'Descrição', style: 'tableHeader', fontSize: 7 },
              { text: 'Valor', style: 'tableHeader', fontSize: 7 }
            ],
            ...movimentoCaixa,
          ]
        }, layout: 'lightHorizontalLines'
      }, {
        text:
          '', margin: [0, 10],

      },{
        table: {
          widths: [430, 70],
          body: [
            [{text: 'CRÉDITO:', style: 'tableHeader', fontSize: 8, alignment: 'right',border: [false]},{text: totalCreditoCaixa, fontSize: 8, alignment: 'right',border: [false]},],
            [{text: 'DÉBITO:', style: 'tableHeader', fontSize: 8, alignment: 'right',border: [false]},{text: totalDebitoCaixa, fontSize: 8, alignment: 'right',border: [false]}],
            [{text: 'SALDO:', style: 'tableHeader', fontSize: 8, alignment: 'right',border: [false]},{text: totalPeriodoCaixa, fontSize: 8, alignment: 'right',border: [false]}],
          ],
        }, layout: 'lightHorizontalLines'
      }
    ]
  }).open();

window.location.reload()
}
export default CriarPdf;