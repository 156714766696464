import React from "react";
export const GlobalContext = React.createContext();

const storage = localStorage.getItem('profile');
const servidor = 'https://api.franciscanos.org.br:7000';
// const servidor = 'http://10.90.1.101:3000';

const fraternidade = [
  {
    "id": 0,
    "nome": "PROVINCIA",
    "descricao": "Provincia Franciscana",
    "cob": null
  },
  {
    "id": 1,
    "nome": "PROVINCIA",
    "descricao": "PROVINCIA",
    "cob": null
  },
  {
    "id": 2,
    "nome": "OFM - BANCO",
    "descricao": "OFM",
    "cob": 'BANCO'
  },
  {
    "id": 3,
    "nome": "OFM - CAIXA",
    "descricao": "OFM",
    "cob": "2"
  },
  {
    "id": 4,
    "nome": "OFM SEDE - BANCO",
    "descricao": "OFM SEDE",
    "cob": "BANCO"
  },
  {
    "id": 5,
    "nome": "OFM SEDE - CAIXA",
    "descricao": "OFM SEDE",
    "cob": "4"
  },
  {
    "id": 6,
    "nome": "OFM KATEPA - BANCO",
    "descricao": "OFM KATEPA",
    "cob": "BANCO"
  },
  {
    "id": 7,
    "nome": "OFM KATEPA - CAIXA",
    "descricao": "OFM KATEPA",
    "cob": "6"
  },
  {
    "id": 8,
    "nome": "OFM PALANCA - BANCO",
    "descricao": "OFM PALANCA",
    "cob": "BANCO"
  },
  {
    "id": 9,
    "nome": "OFM PALANCA - CAIXA",
    "descricao": "OFM PALANCA",
    "cob": "8"
  },
  {
    "id": 10,
    "nome": "OFM QUIBALA - BANCO",
    "descricao": "OFM QUIBALA",
    "cob": "BANCO"
  },
  {
    "id": 11,
    "nome": "OFM QUIBALA - CAIXA",
    "descricao": "OFM QUIBALA",
    "cob": "10"
  },
  {
    "id": 12,
    "nome": "OFM SEMINÁRIO - BANCO",
    "descricao": "OFM SEMINÁRIO",
    "cob": "BANCO"
  },
  {
    "id": 13,
    "nome": "OFM SEMINÁRIO - CAIXA",
    "descricao": "OFM SEMINÁRIO",
    "cob": "12"
  },
  {
    "id": 14,
    "nome": "OFM VIANA - BANCO",
    "descricao": "OFM VIANA",
    "cob": "BANCO"
  },
  {
    "id": 15,
    "nome": "OFM VIANA - CAIXA",
    "descricao": "OFM VIANA",
    "cob": "14"
  },
  {
    "id": 16,
    "nome": "SAV - BANCO",
    "descricao": "SAV",
    "cob": "BANCO"
  },
  {
    "id": 17,
    "nome": "SAV - CAIXA",
    "descricao": "SAV",
    "cob": "16"
  },
  {
    "id": 18,
    "nome": "SECRETARIA - BANCO",
    "descricao": "SECRETARIA",
    "cob": "BANCO"
  },
  {
    "id": 19,
    "nome": "SECRETARIA - CAIXA",
    "descricao": "SECRETARIA",
    "cob": "18"
  },
  {
    "id": 20,
    "nome": "KIMBO SAO FRANCISCO - BANCO",
    "descricao": "SECRETARIA",
    "cob": "BANCO"
  },
  {
    "id": 21,
    "nome": "KIMBO SAO FRANCISCO - CAIXA",
    "descricao": "SECRETARIA",
    "cob": "20"
  },
  {
    "id": 22,
    "nome": "PALANCA SANTA CLARA - BANCO",
    "descricao": "SECRETARIA",
    "cob": "BANCO"
  },
  {
    "id": 23,
    "nome": "PALANCA SANTA CLARA - CAIXA",
    "descricao": "SECRETARIA",
    "cob": "22"
  }
]
let profileJson = JSON.parse(storage)
if (profileJson === null) {
  profileJson = ' ';
}

export const GlobalProvider = ({ children }) => {
  const [profile, setProfile] = React.useState(null)
  React.useEffect(() => {
    setProfile(profileJson)
  }, [])
  
  
  if (profile === null) return null
  return (
    <GlobalContext.Provider value={{ profile, servidor, fraternidade }}>
      {children}
    </GlobalContext.Provider>
  )
}

